import { AbpHttpInterceptor } from "@abp/abpHttpInterceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import * as ApiServiceProxies from "./service-proxies";
import { EmployeeReportsServiceServiceProxy, AxIntegrationsServiceProxy } from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.ZoneSettingServiceProxy,
        ApiServiceProxies.ResignationsServiceProxy,
        ApiServiceProxies.DeputationsServiceProxy,
        ApiServiceProxies.OrderMaterialsServiceProxy,
        ApiServiceProxies.RemoteWorksServiceProxy,
        ApiServiceProxies.AxIntegrationsServiceProxy,
        ApiServiceProxies.ExportedDatasServiceProxy,
        ApiServiceProxies.OverTimesServiceProxy,
        ApiServiceProxies.LoansServiceProxy,
        ApiServiceProxies.HrFormsServiceProxy,
        ApiServiceProxies.GeneralPurposesServiceProxy,
        ApiServiceProxies.DirectWorksServiceProxy,
        ApiServiceProxies.MessageReceiversServiceProxy,
        ApiServiceProxies.MessagesServiceProxy,
        ApiServiceProxies.EmployeeReportsServiceServiceProxy,
        ApiServiceProxies.ClearancesServiceProxy,
        ApiServiceProxies.HoursLeavesServiceProxy,
        ApiServiceProxies.ReasonCodesServiceProxy,
        ApiServiceProxies.EmployeeAdminStatusesServiceProxy,
        ApiServiceProxies.EmployeeStepsServiceProxy,
        ApiServiceProxies.WorkflowServiceProxy,
        ApiServiceProxies.RequestServiceProxy,
        ApiServiceProxies.RequestTypesServiceProxy,
        ApiServiceProxies.LeaveCodesServiceProxy,
        ApiServiceProxies.EmployeeBanksServiceProxy,
        ApiServiceProxies.VacationsServiceProxy,
        ApiServiceProxies.EmployeeDocumentsServiceProxy,
        ApiServiceProxies.EmployeeFamiliesServiceProxy,
        ApiServiceProxies.EmployeeContactsServiceProxy,
        ApiServiceProxies.EmployeesServiceProxy,
        ApiServiceProxies.PositionsServiceProxy,
        ApiServiceProxies.DepartmentsServiceProxy,
        ApiServiceProxies.JobsServiceProxy,
        ApiServiceProxies.BranchsServiceProxy,
        ApiServiceProxies.CitiesServiceProxy,
        ApiServiceProxies.CountriesServiceProxy,
        ApiServiceProxies.SectorsServiceProxy,
        ApiServiceProxies.LookupsServiceProxy,
        ApiServiceProxies.LookupTypesServiceProxy,
        ApiServiceProxies.PersonServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.TimesheetServiceProxy,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AbpHttpInterceptor,
            multi: true
        }
    ]
})
export class ServiceProxyModule { }
