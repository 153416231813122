import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'app-filter-items',
    templateUrl: './filter-items.component.html',
    styleUrls: ['./filter-items.component.css']
})
export class FilterItemsComponent implements OnInit {
    @ViewChild('toggleButton') toggleButton: ElementRef;
    @Input() title: string;
    @Input() filterVal: string;
    @Input() datepicker: boolean = false;

    constructor(private renderer: Renderer2) {

        this.renderer.listen('window', 'click', (e) => {
            if (!e.target.closest('.select-filter')) {
                document.querySelectorAll('.select-filter--box-items').forEach((e) => e.classList.remove('show'))
            }

        });
    }


    changeFilter(event) {
        //if (event.currentTarget.nextSibling.classList.contains('show') || event.currentTarget.nextSibling.classList.contains('datepicker')) {
        //    event.currentTarget.nextSibling.classList.remove('show')
        //} else {
        //    document.querySelectorAll('.select-filter--box-items').forEach((e) => e.classList.remove('show'))
        //    event.currentTarget.nextSibling.classList.add('show')
        //}
        document.querySelectorAll('.select-filter--box-items').forEach((e) => e.classList.remove('show'))
        // if (!event.currentTarget.nextSibling.classList.contains('datepicker')) {
            event.currentTarget.nextSibling.classList.add('show')
    }


    // onClick(event) {
    //     if (!this._eref.nativeElement.contains(event.target)) {
    //         document.querySelectorAll('.select-filter--box-items').forEach((e) => e.classList.add('ddddd'))
    //     }
    // }

    ngOnInit() {
        document.querySelectorAll("select-filter--box-items").forEach((e) => {
            e.addEventListener('click', function () {
                this.classList.add("show")
            })
        })
    }

}
