import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule, TabsModule } from "ngx-bootstrap";
import { TableModule } from "primeng/table";
import { FileUploadModule } from "ng2-file-upload";
import { AutoCompleteModule, EditorModule, FileUploadModule as PrimeNgFileUploadModule, InputMaskModule, PaginatorModule, PanelModule, TreeModule } from "primeng/primeng";
import { CreateOrEditUserModalComponent } from "./create-or-edit-user-modal.component";
import { UtilsModule } from "@shared/utils/utils.module";
import { SharedModule } from "../shared-modules/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
    imports: [
        NgbModule,
        TreeModule,
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TableModule,
        FileUploadModule,
        PrimeNgFileUploadModule,
        PaginatorModule,
        PanelModule,
        UtilsModule,
        SharedModule
    ],
    declarations: [CreateOrEditUserModalComponent],
    exports: [CreateOrEditUserModalComponent]
})
export class UserInfoModule { }
