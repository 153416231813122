import { Component, Injector, ViewChild, ViewEncapsulation, OnInit, NgZone } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GetNotificationsOutput, NotificationServiceProxy, UserNotification, UserNotificationState, ChangeRequestStatusInput, NameValueOfString, RequestStatusEnum } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { LazyLoadEvent } from 'primeng/components/common/lazyloadevent';
import { Paginator } from 'primeng/components/paginator/paginator';
import { Table } from 'primeng/components/table/table';
import { IFormattedUserNotification, UserNotificationHelper } from './UserNotificationHelper';
import { finalize } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';
import { ViewHoursLeaveModalComponent } from '../../../main/requests/hoursLeaves/view-hoursLeave-modal.component';
import { ViewDirectWorkModalComponent } from '../../../main/requests/directWorks/view-directWork-modal.component';
import { ViewGeneralPurposeModalComponent } from '../../../main/requests/generalPurposes/view-generalPurpose-modal.component';
import { ViewHrFormModalComponent } from '../../../main/requests/hrForms/view-hrForm-modal.component';
import { ViewLoanModalComponent } from '../../../main/requests/loans/view-loan-modal.component';
import { ViewOverTimeModalComponent } from '@app/main/requests/overTimes/view-overTime-modal.component';
import { ViewClearanceModalComponent } from '../../../main/requests/clearances/view-clearance-modal.component';
import { ViewVacationModalComponent } from '@app/main/requests/vacations/view-vacation-modal.component';
import { ViewRemoteWorkModalComponent } from '@app/main/requests/remote-works/view-remote-work-modal.component';
import { ViewOrderMaterialModalComponent } from '@app/main/requests/order-materials/view-order-material-modal.component';
import { ViewDeputationModalComponent } from '@app/main/requests/deputations/view-deputation-modal.component';
import { ViewResignationModalComponent } from '@app/main/requests/resignations/view-resignation-modal.component';
import { Router } from '@angular/router';

@Component({
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.less'],
    selector: "NotificationsComponent",
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class NotificationsComponent extends AppComponentBase implements OnInit {

    @ViewChild('dataTable') dataTable: Table;
    @ViewChild('paginator') paginator: Paginator;
    @ViewChild("viewVacationModal") viewVacationModal: ViewVacationModalComponent;
    @ViewChild("viewClearanceModal") viewClearanceModal: ViewClearanceModalComponent;
    @ViewChild("viewHoursLeaveModal") viewHoursLeaveModal: ViewHoursLeaveModalComponent;
    @ViewChild("viewDirectWorkModal") viewDirectWorkModal: ViewDirectWorkModalComponent;
    @ViewChild("viewGeneralPurposeModal") viewGeneralPurposeModal: ViewGeneralPurposeModalComponent;
    @ViewChild("viewHrFormModal") viewHrFormModal: ViewHrFormModalComponent;
    @ViewChild("viewLoanModal") viewLoanModal: ViewLoanModalComponent;
    @ViewChild("viewOverTimeModal") viewOverTimeModal: ViewOverTimeModalComponent;
    @ViewChild("viewRemoteWorkModal") viewRemoteWorkModal: ViewRemoteWorkModalComponent;
    @ViewChild("viewOrderMaterialModal") viewOrderMaterialModal: ViewOrderMaterialModalComponent;
    @ViewChild("viewDeputationModal") viewDeputationModal: ViewDeputationModalComponent;
    @ViewChild("viewResignationModal") viewResignationModal: ViewResignationModalComponent;

    tempClickedNotificationId: string;

    readStateFilter = 'ALL';
    loading = false;
    allNotifications: IFormattedUserNotification[] = [];
    active = false;
    saving = false;
    employee: NameValueOfString = new NameValueOfString();
    showCommentSections: boolean =  false;
    commentSectionId = 0;
    approveConstant: string = RequestStatusEnum.Approved.toString();
    rejectConstant: string = RequestStatusEnum.Rejected.toString();

    constructor(
        injector: Injector,
        private _notificationService: NotificationServiceProxy,
        private _userNotificationHelper: UserNotificationHelper,
        public _zone: NgZone,
        private _router: Router
    ) {
        super(injector);
    }
    ngOnInit(): void {
        this.registerToEvents();

        setInterval(() => {
            this.getNotifications();
        }, 300000)
    }

    registerToEvents() {
        let self = this;

        function onNotificationReceived(userNotification) {
            self._userNotificationHelper.show(userNotification);
            self.getNotifications();
        }

        abp.event.on("abp.notifications.received", userNotification => {
            self._zone.run(() => {
                onNotificationReceived(userNotification);
            });
        });

        function onNotificationsRefresh() {
            self.getNotifications();
        }

        abp.event.on("app.notifications.refresh", () => {
            this.approveOrRejectModel = new ChangeRequestStatusInput(); ;
            this.showCommentSections = false;
            this.commentSectionId =  0;
            self._zone.run(() => {
                onNotificationsRefresh();
            });
        });
    
        // function onNotificationsRead(userNotificationId) {
        //     for (let i = 0; i < self.primengTableHelper.records.length; i++) {
        //         if (self.primengTableHelper.records[i].userNotificationId === userNotificationId) {
        //             self.primengTableHelper.records[i].state = "READ";
        //         }
        //     }

        //     self.unreadNotificationCount -= 1;
        // }

         abp.event.on("app.notifications.read.id", userNotificationId => {
             self._zone.run(() => {
                 this.setNotificationAsReadById(userNotificationId);
                 this.removeNotificationById(userNotificationId);
                 //abp.event.trigger("app.notifications.refresh");
             });
        });
    }

    initalize() {
        this.approveOrRejectModel = new ChangeRequestStatusInput();
        this.active = true;
        this.saving = false;
    }
    
    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }

    setAsRead(record: any): void {
        this.setNotificationAsRead(record, () => {
            this.reloadPage();
        });
    }

    isRead(record: any): boolean {
        return record.formattedNotification.state === 'READ';
    }

    fromNow(date: moment.Moment): string {
        return moment(date).fromNow();
    }

    formatRecord(record: any): IFormattedUserNotification {
        return this._userNotificationHelper.format(record, false);
    }

    formatNotification(record: any): string {
        const formattedRecord = this.formatRecord(record);
        return abp.utils.truncateStringWithPostfix(formattedRecord.text, 120);
    }

    formatNotifications(records: any[]): any[] {
        const formattedRecords = [];
        for (const record of records) {
            record.formattedNotification = this.formatRecord(record);
            formattedRecords.push(record);
        }
        return formattedRecords;
    }

    truncateString(text: any, length: number): string {
        return abp.utils.truncateStringWithPostfix(text, length);
    }

    getNotifications(event?: LazyLoadEvent): void {
        // if (this.primengTableHelper.shouldResetPaging(event)) {
        //     this.paginator.changePage(0);

        //     return;
        // }

        // this.primengTableHelper.showLoadingIndicator();

        // this._notificationService.getUserNotifications(
        //     //this.readStateFilter === 'ALL' ? undefined : UserNotificationState.Unread,
        //     UserNotificationState.Unread,
        //     this.primengTableHelper.getMaxResultCount(this.paginator, event),
        //     this.primengTableHelper.getSkipCount(this.paginator, event)
        // ).pipe(finalize(() => this.primengTableHelper.hideLoadingIndicator())).subscribe((result) => {
        //     this.primengTableHelper.totalRecordsCount = result.totalCount;
        //     this.primengTableHelper.records = this.formatNotifications(result.items);
        //     this.primengTableHelper.hideLoadingIndicator();
        // });
        this._employeesServiceProxy.getCurrentLoggedEmployee().subscribe(result => {
            if (result.id != 0) {
                this.employee.value = result.id.toString();
                this.employee.name = result.fullName;
            }
        });
        this._notificationService.getUserNotifications(
            this.readStateFilter === 'ALL' ? undefined : UserNotificationState.Unread,
            //UserNotificationState.Unread,
            1000,
            0
        ).subscribe((result) => {
            this.allNotifications = [];
            //console.log(result.items);
            if (result && result.items) {
                this.allNotifications = result.items.map(e => this.formatRecord(e));
                abp.event.trigger('app.notification.unreadNotificationCountChanged', result.unreadCount);
            }
           console.log(this.allNotifications);
        });

        //allNotifications
    }

    setAllNotificationsAsRead(): void {
        this._userNotificationHelper.setAllAsRead(() => {
            this.getNotifications();
        });
    }

    openNotificationSettingsModal(): void {
        this._userNotificationHelper.openSettingsModal();
    }

    setNotificationAsRead(userNotification: UserNotification, callback: () => void): void {
        this._userNotificationHelper
            .setAsRead(userNotification.id, () => {
                if (callback) {
                    callback();
                }
            });
    }
    setNotificationAsReadById(id: string): void {
        this._userNotificationHelper.setAsRead(id);
    }

    setAsReaded() {

        if (this.tempClickedNotificationId) {
            this._userNotificationHelper.setAsRead(this.tempClickedNotificationId, () => {
                this.tempClickedNotificationId = null;
                this.getNotifications();
            });
        }
    }

    deleteNotification(userNotification: UserNotification): void {
        this.message.confirm(
            this.l('NotificationDeleteWarningMessage'), this.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
                    this._notificationService.deleteNotification(userNotification.id)
                        .subscribe(() => {
                            this.reloadPage();
                            this.notify.success(this.l('SuccessfullyDeleted'));
                        });
                }
            }
        );
    }

    public getRowClass(formattedRecord: IFormattedUserNotification): string {
        return formattedRecord.state === 'READ' ? 'notification-read' : '';
    }

    onShowRequest(url) {
        this.viewVacationModal.showApproval(url, false);
    }

    getNotificationText(record): string {

        if (record.title == "EmployeeDocuments") {
            return this.truncateString(this.l(record.text), 120);
        }

        return this.truncateString(record.text, 120);
    }

    onClickNotification(requestType: string, wfRequestId: number, notificationId: string) {
        this.tempClickedNotificationId = notificationId;


        switch (requestType) {
            case "Vacation":
                this.viewVacationModal.showApproval(wfRequestId);
                break;

            case "Hours_Leave":
                this.viewHoursLeaveModal.showApproval(wfRequestId);
                break;

            case "Clearance":
                this.viewClearanceModal.showApproval(wfRequestId);
                break;

            case "DirectWork":
                this.viewDirectWorkModal.showApproval(wfRequestId);
                break;

            case "GeneralPurpose":
                this.viewGeneralPurposeModal.showApproval(wfRequestId);
                break;

            case "HrForm":
                this.viewHrFormModal.showApproval(wfRequestId);
                break;

            case "Loan":
                this.viewLoanModal.showApproval(wfRequestId);
                break;

            case "OverTime":
                this.viewOverTimeModal.showApproval(wfRequestId);
                break;

            case "EmployeeDocuments": {
                window.open('/app/main/my-profile', '_blank');
                break;
            }

            case "PaySlip":
                break;

            case "RemoteWork":
                this.viewRemoteWorkModal.showApproval(wfRequestId);
                break;
            case "OrderMaterial":
                this.viewOrderMaterialModal.showApproval(wfRequestId);
                break;
            case "Deputation":
                this.viewDeputationModal.showApproval(wfRequestId);
                break;
            case "Resignation":
                this.viewResignationModal.showApproval(wfRequestId);
                break;
            case "Timesheet":
                this._router.navigate(['/app/main/employees/timesheet'], { queryParams: { wfRequestId: wfRequestId } });
                break;
            default:
                this.message.error("No Request Type For That Notification , Please Contact System Admin.");
        }
        this.setAsReaded();
    }
    dateToFromNowDaily(myDate) {

        // get from-now for this date
        var fromNow = moment(myDate).fromNow();

        // ensure the date is displayed with today and yesterday
        var time = moment(myDate).calendar(null, {
            // when the date is closer, specify custom values
            lastWeek: '[Last] dddd',
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            // when the date is further away, use from-now functionality
            sameElse: function () {
                return "[" + fromNow + "]";
            }
        });
        //console.log(time);
        return time;
    }
    getDefaultProfilePhoto() {
        var defaultProfilePhoto = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        return defaultProfilePhoto;
    }
    onEnableApproveRejectButtons(pendingEmployeeId?: number, title?: string) {
        return pendingEmployeeId && pendingEmployeeId == Number(this.employee.value)
            && (title != "EmployeeDocuments" && title != "PaySlip");
    }
    approveOrRejectModel: ChangeRequestStatusInput = new ChangeRequestStatusInput();

    onConfirmApproveReject(requestType: string, notificationId: string) {
        this.loading = true;
        var wfRequestId = this.approveOrRejectModel.requestId;
        var actionType = this.approveOrRejectModel.requestStatus;
        console.log(this.approveOrRejectModel.requestId, notificationId + " | "+ this.approveOrRejectModel.requestStatus + " | "+ this.approveOrRejectModel.comment);
        //return;
        this.message.confirm(
            this.l('ConfirmationMessageApprove'), this.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
                    (window as any).BusyD365UI();
                    switch (requestType) {
                        case "Vacation":
                            this.viewVacationModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            //console.log(this.approveOrRejectModel);
                            break;
            
                        case "Hours_Leave":
                            this.viewHoursLeaveModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
            
                        case "Clearance":
                            this.viewClearanceModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
            
                        case "DirectWork":
                            this.viewDirectWorkModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
            
                        case "GeneralPurpose":
                            this.viewGeneralPurposeModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
            
                        case "HrForm":
                            this.viewHrFormModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
            
                        case "Loan":
                            this.viewLoanModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
           
                        case "OverTime":
                            this.viewOverTimeModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
            
                        // case "EmployeeDocuments":{
                        //     this.setAsReaded();
                        //     window.open('/app/main/my-profile', '_blank');
                        //     break;
                        // }
                            
                        // case "PaySlip":
                        //     this.setAsReaded();
                        //     break;
            
                        case "RemoteWork":
                            this.viewRemoteWorkModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
                        case "OrderMaterial":
                            this.viewOrderMaterialModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
                        case "Deputation":
                            this.viewDeputationModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
                        case "Resignation":
                            this.viewResignationModal.onApproveRejectExternal(wfRequestId, actionType, notificationId, this.approveOrRejectModel.comment);
                            break;
                        default:
                            this.message.error("No Request Type For That Notification , Please Contact System Admin.");
                    }
                }
            }
        );

    }
    onApproveReject(actionType: string, wfRequestId: number) {
        this.approveOrRejectModel.requestStatus = actionType == 'approve' ?  RequestStatusEnum.Approved :  RequestStatusEnum.Rejected;
        this.showCommentSections = !this.showCommentSections;
        this.commentSectionId = this.showCommentSections ? wfRequestId : 0;
        this.approveOrRejectModel.requestId = this.commentSectionId;
        this.approveOrRejectModel.comment = '';
    }
    removeNotificationById(notificationId: string) {
        if(!this.allNotifications)
            return;
        this.allNotifications = this.allNotifications.filter(notification => notification.userNotificationId.toLowerCase() !== notificationId.toLowerCase());
        //Refreshing allNotification locally.
        this.approveOrRejectModel = new ChangeRequestStatusInput(); ;
        this.showCommentSections = false;
        this.commentSectionId =  0;
    }
}
