import { Pipe, PipeTransform } from "@angular/core";
import { isArray } from "lodash";

@Pipe({
  name: "filterValue",
})
export class FilterValuesPipe implements PipeTransform {
  transform(value: any): any {

    if(value == null || value.length == 0){
        return null
    }

    if(Array.isArray(value)){
        if (value.length > 1) {
            if (value[0].name != null) {
                return (
                value[0].name +
                ` +${value.length - 1}`
                );
            } else {
                if (value.length == 2) {
                    return value[1].name;
                } else {
                    return (
                    value[1].name +
                    ` +${value.length - 2}`
                    );
                }
                }
        } else {
            return value[0].name;
        }
    } else {
        return value.name
    }

  }
}
