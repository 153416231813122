import { Component, ViewChild, Injector, Output, EventEmitter, OnInit } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { GetVacationForViewDto, VacationDto, VacationsServiceProxy, ChangeRequestStatusInput, RequestStatusEnum, EmployeesServiceProxy, NameValueOfString } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { reject } from "lodash";
import { finalize, map } from "rxjs/operators";
import { ApprovalHistoryComponent } from "@app/shared/view-vacation/approval-history/approval-history.component";
import { ActivatedRoute, Router } from "@angular/router";
import { AppConsts } from "@shared/AppConsts";

@Component({
    selector: "viewVacationModal",
    templateUrl: "./view-vacation-modal.component.html"
})
export class ViewVacationModalComponent extends AppComponentBase implements OnInit {
    @ViewChild("createOrEditModal") modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("approvalHistoryComponent")
    approvalHistoryComponent: ApprovalHistoryComponent;

    active = false;
    saving = false;
    showApprovalActions = false;
    employee: NameValueOfString = new NameValueOfString();
    index = 1;
    item: GetVacationForViewDto;
    wfRequestId: number;
    isCancel = false;
    isPopup = true;
    notificationId: string;

    constructor(injector: Injector, private _vacationsServiceProxy: VacationsServiceProxy,
        private router: Router,
        private route: ActivatedRoute) {
        super(injector);
        this.initalize();
    }

    ngOnInit() {
        this.route.params.pipe(map(p => p['wfRequestId']))
            .subscribe((wfRequestId) => {
                if (isNaN(wfRequestId))
                    return;
                this.showApproval(+wfRequestId, false, false);
            });

        this.getCurrentEmployee();
    }

    initalize() {
        this.item = new GetVacationForViewDto();
        this.item.vacation = new VacationDto();
        this.item.wfRequestAction = [];
        this.approveOrRejectModel = new ChangeRequestStatusInput();

        this.showApprovalActions = false;

        this.active = true;
        this.saving = false;
        this.index = 1;
    }

    show(item: GetVacationForViewDto): void {
        this.isCancel = false;
        this.initalize();

        this.wfRequestId = item.vacation.wfRequestId;

        this._vacationsServiceProxy.getVacationForView(item.vacation.id).subscribe(result => {
            this.item = result;

            this.approvalHistoryComponent.load(this.item.wfRequestAction);
            this.active = true;
            this.modal.show();
        });

        this.modal.show();
    }
    onApproveRejectExternal(wfRequestId: number, actionType: RequestStatusEnum, notificationId: string, comments?: string): void {
        if (isNaN(wfRequestId))
        return;
        this.isPopup = false;
        this.isCancel = false; 
        this.initalize();
        this.approveOrRejectModel = new ChangeRequestStatusInput();
        this.approveOrRejectModel.requestId = wfRequestId;
        this.approveOrRejectModel.employeeId = Number(this.employee.value);
        this.approveOrRejectModel.comment = comments;
        this.wfRequestId = wfRequestId;
        this.notificationId = notificationId;
        this._vacationsServiceProxy.getVacationApproval(wfRequestId).subscribe(result => {
            this.item = result;
            this.showApprovalActions = !this.isCancel;        
            this.active = true;
            //console.log("onApproveRejectExternal "+ actionType);
            if(actionType == RequestStatusEnum.Approved){
                this.beforeApprove();
            }
            else{
                this.reject();
            }
        });    
    }
    showApproval(wfRequestId: number, isCancel?: boolean, isPopup = true): void {
        if (isNaN(wfRequestId))
            return;
        this.isPopup = isPopup;
        this.isCancel = isCancel;       
        this.initalize();
        this.wfRequestId = wfRequestId;
        this._vacationsServiceProxy.getVacationApproval(wfRequestId).subscribe(result => {
            this.item = result;
            this.showApprovalActions = !this.isCancel;        
            this.approvalHistoryComponent.load(this.item.wfRequestAction);
            this.active = true;

            if (this.isPopup)
                this.modal.show();

        });
    }

    close(): void {
        this.initalize();
        this.active = false;
        if (this.isPopup)
            this.modal.hide();
        else
            this.router.navigate(['/app/main/dashboard']);
    }

    approveOrRejectModel: ChangeRequestStatusInput = new ChangeRequestStatusInput();

    beforeApprove() {
        console.log("before approve "+ this.item.requestsWithInDates);
        if (this.item.requestsWithInDates && this.item.requestsWithInDates.length > 0) {
            (window as any).FreeeD365UI();
            this.message.confirm(this.l('RequestWithInDatesFound'), isConfirmed => {
                if (isConfirmed)
                    this.approve();
            });
        }
        else {
            (window as any).FreeeD365UI();
            this.approve();
        }
    }

    approve() {
        this.saving = true;
        this.active = false;

        this.approveOrRejectModel.requestId = this.wfRequestId;
        this.approveOrRejectModel.requestStatus = RequestStatusEnum.Approved;
        console.log(this.approveOrRejectModel);
        abp.event.trigger("app.notifications.read.id", this.notificationId);
        this._vacationsServiceProxy
            .approve(this.approveOrRejectModel)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.notify.info(this.l("SavedSuccessfully"));
                this.close();
                this.modalSave.emit(null);
            });
    }

    reject() {
        if (!this.approveOrRejectModel.comment) {
            this.message.error(this.l("CommentValidationMessage"), this.l("ValidationError"));
            return;
        }

        this.saving = true;
        this.active = false;

        this.approveOrRejectModel.requestId = this.wfRequestId;
        this.approveOrRejectModel.requestStatus = RequestStatusEnum.Rejected;
        console.log("Reject API", this.approveOrRejectModel);
        this._vacationsServiceProxy
            .reject(this.approveOrRejectModel)
            .pipe(
                finalize(() => {
                    this.saving = false;

                    abp.event.trigger("app.notifications.read.id", this.notificationId);
                })
            )
            .subscribe(() => {
                this.notify.info(this.l("SavedSuccessfully"));
                this.close();
                this.modalSave.emit(null);
            });
    }

    cancel() {
        this.saving = true;
        this.active = false;

        this.approveOrRejectModel.requestId = this.wfRequestId;
        this.approveOrRejectModel.requestStatus = RequestStatusEnum.Canceled;

        this._vacationsServiceProxy
            .cancel(this.approveOrRejectModel)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.notify.info(this.l("SavedSuccessfully"));
                this.close();
                this.modalSave.emit(null);
            });

    }

    onEnableApproveRejectButtons() {
        return this.item.pendingEmployeeId == Number(this.employee.value) && this.item.requestStatusId == 1 && !this.isCancel;
    }

    getCurrentEmployee() {
        this._employeesServiceProxy.getCurrentLoggedEmployee().subscribe(result => {
            this.employee = new NameValueOfString();
            this.employee.name = result.fullName;
            this.employee.value = result.id.toString();
        });
    }

    OnClickDownload() {
        const url = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/DownloadFileFromBinaryObject?fileId=" + this.item.vacation.attachmentId+ "&fileName="+ this.item.vacation.fileName;;
        window.open(url, '_blank');
    }

}
