import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule, TabsModule } from "ngx-bootstrap";
import { TableModule } from "primeng/table";
import { FileUploadModule } from "ng2-file-upload";
import { FileUploadModule as PrimeNgFileUploadModule, InputMaskModule, PaginatorModule, PanelModule } from "primeng/primeng";
import { ApprovalHistoryComponent } from "./approval-history/approval-history.component";
import { ViewOverTimeModalComponent } from "@app/main/requests/overTimes/view-overTime-modal.component";
import { ViewLoanModalComponent } from "@app/main/requests/loans/view-loan-modal.component";
import { ViewHrFormModalComponent } from "@app/main/requests/hrForms/view-hrForm-modal.component";
import { ViewGeneralPurposeModalComponent } from "@app/main/requests/generalPurposes/view-generalPurpose-modal.component";
import { ViewDirectWorkModalComponent } from "@app/main/requests/directWorks/view-directWork-modal.component";
import { ViewHoursLeaveModalComponent } from "@app/main/requests/hoursLeaves/view-hoursLeave-modal.component";
import { UtilsModule } from "@shared/utils/utils.module";
import { ViewClearanceModalComponent } from "@app/main/requests/clearances/view-clearance-modal.component";
import { ViewVacationModalComponent } from "@app/main/requests/vacations/view-vacation-modal.component";
import { ViewRemoteWorkModalComponent } from "@app/main/requests/remote-works/view-remote-work-modal.component";
import { ViewOrderMaterialModalComponent } from "@app/main/requests/order-materials/view-order-material-modal.component";
import { ViewDeputationModalComponent } from "@app/main/requests/deputations/view-deputation-modal.component";
import { ViewResignationModalComponent } from "@app/main/requests/resignations/view-resignation-modal.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TableModule,
        FileUploadModule,
        PrimeNgFileUploadModule,
        PaginatorModule,
        PanelModule,
        UtilsModule,

    ],
    declarations: [
        ViewResignationModalComponent,
        ViewVacationModalComponent,
        ViewDeputationModalComponent,
        ViewOverTimeModalComponent,
        ViewOrderMaterialModalComponent,
        ViewLoanModalComponent,
        ViewHrFormModalComponent,
        ViewRemoteWorkModalComponent,
        ViewGeneralPurposeModalComponent,
        ViewDirectWorkModalComponent,
        ViewHoursLeaveModalComponent,
        ViewClearanceModalComponent,
        ApprovalHistoryComponent],

    exports: [
        ViewResignationModalComponent,
        ViewVacationModalComponent,
        ViewDeputationModalComponent,
        ViewHrFormModalComponent,
        ViewRemoteWorkModalComponent,
        ViewOverTimeModalComponent,
        ViewOrderMaterialModalComponent,
        ViewGeneralPurposeModalComponent,
        ViewDirectWorkModalComponent,
        ViewLoanModalComponent,
        ViewHoursLeaveModalComponent,
        ViewClearanceModalComponent,
        ApprovalHistoryComponent]
})
export class RequestsViewModule { }
