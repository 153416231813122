import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
export class AppState {
    showNotification: boolean = false;
}
@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    showNotification: boolean = false;

    private readonly _appState = new BehaviorSubject<AppState>(new AppState())
    readonly appState$ = this._appState.asObservable()
    get state(): AppState {
        return this._appState.getValue()
    }

    public handleClick() {
        this.showNotification = !this.showNotification
    }
}
