import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { AppConsts } from '@shared/AppConsts';

@Component({
  selector: 'app-employe-payslip',
  templateUrl: './employe-payslip.component.html',
  styleUrls: ['./employe-payslip.component.css']
})
export class EmployePayslipComponent extends AppComponentBase implements OnInit {

  @ViewChild('employeePaySlipModal') modal: ModalDirective;
  @ViewChild('pdfTableee') invoiceElement!: ElementRef;
  paySlipData: any = {};
  headerData: any;
  defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
  remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

  constructor(injector: Injector,) {
    super(injector);
   }

  ngOnInit() {
  }
  show() {
    this.modal.show();
  }
  onDownloadPDF() {
    html2canvas(this.invoiceElement.nativeElement, { scale: 1,allowTaint: true , useCORS: true }).then((canvas) => {
        const imageGeneratedFromTemplate = canvas.toDataURL('image/png');
        const fileWidth = 200;
        const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
        let PDF = new jsPDF('p', 'mm', 'a4',true);
        PDF.addImage(imageGeneratedFromTemplate, 'PNG', 5, 5, fileWidth, generatedImageHeight,'', 'FAST');
        PDF.html(this.invoiceElement.nativeElement.innerHTML)
        PDF.save(`${this.headerData.reporttitle}.pdf`);
      });
  }
  close() {
    this.modal.hide();
  }
//   getAmountByProperty(prop: string[]) {
//     let total = 0;
//     this.paySlipData.filter(x => prop.includes(x.itemName)).forEach(x => {
//         total = total + x.amount;
//     });
//     return total;
//   }
  l(key: string, ...args: any[]): string {
    args.unshift(key);
    args.unshift(this.localizationSourceName);
    return this.ls.apply(this, args);
  }
  getMonth() {
    if(this.headerData && this.headerData.date) {
        var months = [{ val: 1, text: this.l("January") }, { val: 2, text: this.l("February") }, { val: 3, text: this.l("March") }, { val: 4, text: this.l("April") },
        { val: 5, text: this.l("May") }, { val: 6, text: this.l("June") }, { val: 7, text: this.l("July") }, { val: 8, text: this.l("August") },
        { val: 9, text: this.l("September") }, { val: 10, text: this.l("October") }, { val: 11, text: this.l("November") }, { val: 12, text: this.l("December") }];
        return months.find(x => x.val == Number.parseInt(this.headerData.date.split('-')[0])).text;
    }
  }
  geyYear() {
    if(this.headerData && this.headerData.date) {
        return this.headerData.date.split('-')[1];
    }
  }

  getTotal(item: any) {
    return item.value.map(x => x.amount).reduce((sum, current) => sum + current, 0)
  }

}
