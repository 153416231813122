import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-grid-filters',
    templateUrl: './grid-filters.component.html',
    styleUrls: ['./grid-filters.component.css']
})
export class GridFiltersComponent implements OnInit {
    @Input() showSearch:boolean = true;

    @Input()
    searchModel: string = '';

  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();


changeText(){
    this.change.emit(this.searchModel);

}



    constructor(
    ) {

    }
    ngOnInit(): void {
    }



}
