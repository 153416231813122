import { AbpModule } from "@abp/abp.module";
import * as ngCommon from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppLocalizationService } from "@app/shared/common/localization/app-localization.service";
import { AppNavigationService } from "@app/shared/layout/nav/app-navigation.service";
import { CommonModule } from "@shared/common/common.module";
import { UtilsModule } from "@shared/utils/utils.module";
import { ModalModule } from "ngx-bootstrap";
import { PaginatorModule } from "primeng/primeng";
import { TableModule } from "primeng/table";
import { AppAuthService } from "./auth/app-auth.service";
import { AppRouteGuard } from "./auth/auth-route-guard";
import { CommonLookupModalComponent } from "./lookup/common-lookup-modal.component";
import { EntityTypeHistoryModalComponent } from "./entityHistory/entity-type-history-modal.component";
import { EntityChangeDetailModalComponent } from "./entityHistory/entity-change-detail-modal.component";
import { DateRangePickerInitialValueSetterDirective } from "./timing/date-range-picker-initial-value.directive";
import { DatePickerInitialValueSetterDirective } from "./timing/date-picker-initial-value.directive";
import { DateTimeService } from "./timing/date-time.service";
import { TimeZoneComboComponent } from "./timing/timezone-combo.component";
import { EmployeeLookupTableModalComponent } from "./Employee/employee-lookup-table-modal.component";
import { SecondNavComponent } from "../second-nav/second-nav.component";

@NgModule({
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        AbpModule,
        CommonModule,
        TableModule,
        PaginatorModule
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        EmployeeLookupTableModalComponent,
        SecondNavComponent
    ],

    exports: [
        SecondNavComponent,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        EmployeeLookupTableModalComponent
    ],
    providers: [DateTimeService, AppLocalizationService, AppNavigationService]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppCommonModule,
            providers: [AppAuthService, AppRouteGuard]
        };
    }
}
