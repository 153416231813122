import { Component, ViewChild, Injector, Output, EventEmitter, OnInit } from "@angular/core";
import { ModalDirective, TabsetComponent } from "ngx-bootstrap";
import { GetEmployeeForViewDto, EmployeeDto, EmployeesServiceProxy, NameValueDto, ProfileServiceProxy } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AppConsts } from "@shared/AppConsts";
import { ActivatedRoute, Router } from "@angular/router";
import { EmployeeReportsComponent } from "@app/main/employee-reports/employee-reports.component";

@Component({
    selector: "viewEmployeeModal",
    templateUrl: "./view-employee-modal.component.html",
})
export class ViewEmployeeModalComponent extends AppComponentBase implements OnInit {
    @ViewChild("createOrEditModal") modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("tabSet") tabSet: TabsetComponent;
    @ViewChild("EmployeeReportsModal") employeeReportsModal: EmployeeReportsComponent;


    active = false;
    saving = false;

    currentDate: Date;
    item: GetEmployeeForViewDto;
    empId: any;
    contentArr: any[] = [];
    profilePicture = ''

    constructor(injector: Injector, private router: Router, public _profileServiceProxy: ProfileServiceProxy) {
        super(injector);
        this.item = new GetEmployeeForViewDto();
        this.item.employee = new EmployeeDto();
        this.item.banks = [];
        this.item.families = [];
        this.item.contacts = [];
        this.item.documents = [];
        this.getProfilePicture();



    }

    getProfilePicture(): void {
        this._profileServiceProxy.getProfilePicture().subscribe(result => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }
    ngOnInit() {
        this.currentDate = new Date();
        this.empId = this.getDataFromParam('id');
        let pageType = this.getDataFromParam('pageType');
        if (this.empId) {
            if (pageType == "employee") {
                this.show(this.empId);
            } else {
                this.showEmployeeByUserId(this.empId)
            }
        }
        else {
            this.show(0);
        }
        this.contentArr = this.setNavbarContent()

    }

    show(empId: number): void {
        this._employeesServiceProxy.getEmployeeForView(empId).subscribe(result => {
            // debugger
            this.item = result;
            this.empId = this.item.employee.id;
            this.active = true;
            //this.tabSet.tabs[0].active = true;

        });
        //this.modal.show();
    }

    showEmployeeByUserId(userId: number): void {
        this._employeesServiceProxy.getEmployeeForViewByUserId(userId).subscribe(result => {
            this.item = result;
            this.active = true;
            //this.tabSet.tabs[0].active = true;

        });
        //this.modal.show();
    }

    onClickDownload(fileName) {
        const url = AppConsts.remoteServiceBaseUrl + "/api/FileUpload/DownloadFileEmployeeDocumentAsync?docId=" + fileName;
        location.href = url;
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    setNavbarContent(record?) {
        let navbarContent = [];
        if (this.isGrantedAny('Pages.Employees.Edit', 'Pages.Employees.Delete')) {

            navbarContent.push(
                {
                    text: this.l("VacationBalance"),
                    isMethod: true,
                    link: 'showEmployeeBalance',
                    icon: 'fa fa-hourglass-end',
                    data: record ? record : null,
                });
            navbarContent.push(
                {
                    text: this.l("EmployeeMonthlyPay"),
                    isMethod: true,
                    link: 'showEmployeeMonthlyPay',
                    icon: 'fa fa-money-check',
                    data: record ? record : null,
                });
            navbarContent.push(
                {
                    text: this.l("DownloadEmployeePaySlip"),
                    isMethod: true,
                    link: 'downloadEmployeePaySlip',
                    icon: 'fa fa-download',
                    data: record ? record : null,
                });
        }

        return navbarContent;
    }

    redirect(content) {
        if (this.empId || content.clickable) {
            if (content.isMethod) {
                if (content.data)
                    this[content.link](content.data);
                else {
                    this[content.link]();
                }
            } else {
                if (content.data) {
                    this.router.navigate([content.link],
                        { queryParams: { page: JSON.stringify(content.data) }, skipLocationChange: true })
                } else
                    this.router.navigateByUrl(content.link)
            }
        }
    }

    showEmployeeMonthlyPay(employee: GetEmployeeForViewDto) {
        if (employee) {
            let employeeDto = new NameValueDto();
            employeeDto.value = employee.employee.id.toString();
            employeeDto.name = employee.employee.fullName;
            this.employeeReportsModal.loadEmployeeMonthlyPay(employeeDto);
        }

    }

    showEmployeeBalance(employee: GetEmployeeForViewDto) {
        if (employee) {
            let employeeDto = new NameValueDto();
            employeeDto.value = employee.employee.id.toString();
            employeeDto.name = employee.employee.code + '-' + employee.employee.fullName;
            this.employeeReportsModal.loadEmployeeBalance(employeeDto);
        }
    }
    downloadEmployeePaySlip() {
        if (this.item) {
            let employeeDto = new NameValueDto();
            employeeDto.value = this.item.employee.id.toString();
            employeeDto.name = this.item.employee.fullName;
            console.log(employeeDto)
            this.employeeReportsModal.loadEmployeePaySlip(employeeDto)
        }
    }
}
