import { Component, ViewChild, Injector, Output, EventEmitter, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { GetDirectWorkForViewDto, DirectWorkDto, ChangeRequestStatusInput, NameValueOfString, RequestStatusEnum, EmployeesServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DirectWorksServiceProxy } from '../../../../shared/service-proxies/service-proxies';
import { finalize, map } from 'rxjs/operators';
import { ApprovalHistoryComponent } from '@app/shared/view-vacation/approval-history/approval-history.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'viewDirectWorkModal',
    templateUrl: './view-directWork-modal.component.html'
})
export class ViewDirectWorkModalComponent extends AppComponentBase implements OnInit {

    @ViewChild('createOrEditModal') modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("approvalHistoryComponent") approvalHistoryComponent: ApprovalHistoryComponent;
    active = false;
    saving = false;

    item: GetDirectWorkForViewDto;
    approveOrRejectModel: ChangeRequestStatusInput = new ChangeRequestStatusInput();
    employee: NameValueOfString = new NameValueOfString();
    isCancelRequest = false;
    isPopup = true;
    notificationId: string;

    constructor(
        injector: Injector,
        private _directWorksServiceProxy: DirectWorksServiceProxy,
        private router: Router,
        private route: ActivatedRoute) {
        super(injector);
        this.initalize();
    }
    ngOnInit() {
        this.route.params.pipe(map(p => p['wfRequestId']))
            .subscribe((wfRequestId) => {
                if (isNaN(wfRequestId))
                    return;
                this.showApproval(+wfRequestId, false, false);
            });

        this.getCurrentEmployee();
    }
    initalize() {
        this.item = new GetDirectWorkForViewDto();
        this.item.directWork = new DirectWorkDto();
        this.item.wfRequestAction = [];
        this.approveOrRejectModel = new ChangeRequestStatusInput();
        this.active = true;
        this.saving = false;
        this.isCancelRequest = false;
    }
    show(id: number): void {
        this.isCancelRequest = false;

        this._directWorksServiceProxy.getDirectWorkForViewById(id).subscribe(result => {
            this.item = result;

            if (this.item.directWork.wfRequestId) {
                // Initialize approveOrReject object
                this.approveOrRejectModel = new ChangeRequestStatusInput();
                this.approveOrRejectModel.requestId = this.item.directWork.wfRequestId;
                this.approveOrRejectModel.employeeId = Number(this.employee.value);
                this.approvalHistoryComponent.load(this.item.wfRequestAction);

            }
            this.active = true;
            this.modal.show();
        });
    }

    showApproval(wfRequestId: number, isCancelRequest?: boolean, isPopup = true): void {
        if (isNaN(wfRequestId))
            return;
        this.isPopup = isPopup;
        this.isCancelRequest = isCancelRequest;

        // Initialize approveOrReject object
        this.approveOrRejectModel = new ChangeRequestStatusInput();
        this.approveOrRejectModel.requestId = wfRequestId;
        this.approveOrRejectModel.employeeId = Number(this.employee.value);

        this._directWorksServiceProxy.getDirectWorkForView(wfRequestId).subscribe(result => {
            this.item = result;

            this.approvalHistoryComponent.load(this.item.wfRequestAction);
            this.active = true;
            if (this.isPopup)
                this.modal.show();
        });
    }


    approve() {
        this.saving = true;
        this.active = false;

        // Set Status
        this.approveOrRejectModel.requestStatus = RequestStatusEnum.Approved;

        this._directWorksServiceProxy
            .approve(this.approveOrRejectModel)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.notify.info(this.l("ApprovedSuccessfully"));
                this.close();
                this.modalSave.emit(null);
            });
    }

    reject() {
        if (!this.approveOrRejectModel.comment) {
            this.message.error(this.l("CommentValidationMessage"), this.l("ValidationError"));
            return;
        }

        this.saving = true;
        this.active = false;

        // Set Status
        this.approveOrRejectModel.requestStatus = RequestStatusEnum.Rejected;

        this._directWorksServiceProxy
            .reject(this.approveOrRejectModel)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.notify.info(this.l("RejectedSuccessfully"));
                this.close();
                this.modalSave.emit(null);
            });
    }

    cancelRequest() {
        if (!this.approveOrRejectModel.comment) {
            this.message.error(this.l("CommentValidationMessage"), this.l("ValidationError"));
            return;
        }

        this.saving = true;
        this.active = false;

        // Set Status
        this.approveOrRejectModel.requestStatus = RequestStatusEnum.Canceled;

        this._directWorksServiceProxy
            .cancel(this.approveOrRejectModel)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.notify.info(this.l("CancelledSuccessfully"));
                this.close();

                //reset requestId to handle empty data and approval history
                // this.wfRequestId = null;
                this.modalSave.emit(null);
            });
    }

    onEnableApproveRejectButtons() {
        return this.item.pendingEmployeeId == Number(this.employee.value) && this.item.requestStatusId == 1 && !this.isCancelRequest;
    }

    close(): void {
        this.approveOrRejectModel.comment = "";
        if (!this.onEnableApproveRejectButtons()) {
            this.modalSave.emit(null);
        }
        this.active = false;
        if (this.isPopup)
            this.modal.hide();
        else
            this.router.navigate(['/app/main/dashboard']);
    }

    getCurrentEmployee() {
        this._employeesServiceProxy.getCurrentLoggedEmployee().subscribe(result => {
            this.employee = new NameValueOfString();
            this.employee.name = result.fullName;
            this.employee.value = result.id.toString();
        });
    }
    //salik
    onApproveRejectExternal(wfRequestId: number, actionType: RequestStatusEnum, notificationId: string, comments?: string): void {
        if (isNaN(wfRequestId))
        return;
        this.isPopup = false;
        this.isCancelRequest = false; 
        this.initalize();
        this.approveOrRejectModel.requestId = wfRequestId;
        this.approveOrRejectModel.employeeId = Number(this.employee.value);
        this.approveOrRejectModel.comment = comments;
        this.notificationId = notificationId;
        this._directWorksServiceProxy.getDirectWorkForView(wfRequestId).subscribe(result => {
            this.item = result;
            this.isCancelRequest = !this.isCancelRequest;        
            this.active = true;
            //console.log("onApproveRejectExternal "+ actionType);
            if(actionType == RequestStatusEnum.Approved){
                this.approve();
            }
            else{
                this.reject();
            }
        });    
    }
}
