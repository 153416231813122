import { PermissionCheckerService } from "@abp/auth/permission-checker.service";
import { FeatureCheckerService } from "@abp/features/feature-checker.service";
import { LocalizationService } from "@abp/localization/localization.service";
import { MessageService } from "@abp/message/message.service";
import { AbpMultiTenancyService } from "@abp/multi-tenancy/abp-multi-tenancy.service";
import { NotifyService } from "@abp/notify/notify.service";
import { SettingService } from "@abp/settings/setting.service";
import { Injector } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppUrlService } from "@shared/common/nav/app-url.service";
import { AppSessionService } from "@shared/common/session/app-session.service";
import { AppUiCustomizationService } from "@shared/common/ui/app-ui-customization.service";
import { PrimengTableHelper } from "shared/helpers/PrimengTableHelper";
import { Location } from '@angular/common';
import { UiCustomizationSettingsDto, EmployeeDto, EmployeesServiceProxy, GetEmployeeForViewDto, NameValueDto } from "@shared/service-proxies/service-proxies";
import { ActivatedRoute } from "@angular/router";

export abstract class AppComponentBase {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    activeRoute: ActivatedRoute;
    _employeesServiceProxy: EmployeesServiceProxy;
    _location: Location;
    constructor(injector: Injector) {
        this._location = injector.get(Location);
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.activeRoute = injector.get(ActivatedRoute);
        this._employeesServiceProxy = injector.get(EmployeesServiceProxy);
    }

    flattenDeep(array) {
        return array.reduce((acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)), []);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    excludeExistItems(selectedItems: any[], filteredItems: any[]): any[] {
        if (selectedItems && selectedItems.length > 0) {
            let currentSelected = selectedItems.map(x => x.value);
            filteredItems = filteredItems.filter(elem => {
                return !currentSelected.includes(elem.value);
            });
            return filteredItems;
        }
        return filteredItems;
    }

    getDateFromDateTime(date) {
        if (date) {
            date = new Date(date);
            return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
        } else return date;
    }

    getDataFromParam(key) {
        return this.activeRoute.snapshot.paramMap.get(key);
    }
    get hasOnBehalfPermission() {
        return this.isGranted("Pages.Vacations.EmployeeOnBehalf");
    }

    checkPermission(str): boolean {
        return this.permission.isGranted(str)
    }
    back() {
        this._location.back();
    }

    getNumberOfDaysFromTwoDates(date1, date2) {
        date1 = new Date(date1);
        date2 = new Date(date2);
        return (
            Math.floor(
                (Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) -
                    Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) /
                (1000 * 60 * 60 * 24)
            ) + 1
        );
    }
    isMobileDevice(): boolean {
        return mUtil.isMobileDevice()
        // return this._deviceDetectionService.isMobile();
    }
}
