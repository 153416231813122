import { NotificationSettingsModalComponent } from "./notification-settings-modal.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotificationsComponent } from "./notifications.component";
import { FileUploadModule } from "ng2-file-upload";
import { PaginatorModule, EditorModule, InputMaskModule, TooltipModule, TabViewModule, InputSwitchModule, FieldsetModule } from "primeng/primeng";
import { TableModule } from "primeng/table";
import { FormsModule } from "@angular/forms";
import { ModalModule, TabsModule, BsDatepickerModule, BsDropdownModule } from "ngx-bootstrap";
import { AppCommonModule } from "@app/shared/common/app-common.module";
import { UtilsModule } from "@shared/utils/utils.module";
import { CountoModule } from "angular2-counto";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgbAlertModule, NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { HeaderNotificationsComponent } from "./header-notifications.component";
import { RequestsViewModule } from "@app/shared/view-vacation/requests.view.module";

@NgModule({
    imports: [
        PaginatorModule,
        EditorModule,
        InputMaskModule,
        TableModule,
        CommonModule,
        FormsModule,
        ModalModule,
        TabsModule,
        TooltipModule,
        AppCommonModule,
        UtilsModule,
        CountoModule,
        BsDropdownModule.forRoot(),
        TabViewModule,
        InputSwitchModule,
        NgbAlertModule,
        NgbTimepickerModule,
        FieldsetModule,
        RequestsViewModule
    ],
    declarations: [NotificationsComponent, NotificationSettingsModalComponent, HeaderNotificationsComponent],
    exports: [NotificationsComponent, NotificationSettingsModalComponent, HeaderNotificationsComponent]
})
export class NotificationModule { }
