import { Component, OnInit, ViewEncapsulation, Injector, ViewChild, Output, EventEmitter } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { VacationsServiceProxy, TokenAuthServiceProxy } from '@shared/service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { NotifyService } from 'abp-ng2-module/dist/src/notify/notify.service';
import { WfRequestActionDto } from '../../../../shared/service-proxies/service-proxies';

@Component({
  templateUrl: './approval-history.component.html',
  selector: "approvalHistoryComponent"
})
export class ApprovalHistoryComponent extends AppComponentBase {



  constructor(injector: Injector,
    private _vacationsServiceProxy: VacationsServiceProxy,
    private _notifyService: NotifyService,
    private _tokenAuth: TokenAuthServiceProxy,
    private _activatedRoute: ActivatedRoute) {
    super(injector);
  }

  ngOnInit() {
  }
  items: WfRequestActionDto[] = [];

  load(items: WfRequestActionDto[]) {
    this.primengTableHelper.showLoadingIndicator();
    if (items) {
      console.log(items)
      this.primengTableHelper.totalRecordsCount = items.length;
      this.primengTableHelper.records = items;
      this.primengTableHelper.hideLoadingIndicator();
    }
  }

}
