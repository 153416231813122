import { Component, OnInit, Injector, Output, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'second-nav',
  templateUrl: './second-nav.component.html',
  styleUrls: ['./second-nav.component.css']
})
export class SecondNavComponent extends AppComponentBase implements OnInit {
  @Input('contentArr') contentArr: any;
  @Output() opmethod: EventEmitter<any> = new EventEmitter();
  @Input('empId') empId: any;
  constructor(injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit() {
  }
  fireParent(content) {
    this.opmethod.emit(content);
  }
}
