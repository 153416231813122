import { Injector, ElementRef, Component, OnInit, AfterViewInit, ViewChild, HostBinding, Inject } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { LayoutRefService } from '@metronic/app/core/services/layout/layout-ref.service';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { MenuAsideOffcanvasDirective } from '@metronic/app/core/directives/menu-aside-offcanvas.directive';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { NotificationService } from '@shared/notification.service';
@Component({
    templateUrl: './theme13-layout.component.html',
    selector: 'theme13-layout',
    animations: [appModuleAnimation()]
})
export class Theme13LayoutComponent extends ThemesLayoutBaseComponent implements OnInit, AfterViewInit {

    @ViewChild('mHeader') mHeader: ElementRef;
    @ViewChild('mAsideLeft') mAsideLeft: ElementRef;
    @HostBinding('attr.mMenuAsideOffcanvas') mMenuAsideOffcanvas: MenuAsideOffcanvasDirective;

    constructor(
        injector: Injector,
        private router: Router,
        private layoutRefService: LayoutRefService,
        @Inject(DOCUMENT) private document: Document,
        public notificationService: NotificationService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    }
    downloadPaySlib() {
        this._employeesServiceProxy.getEmployeePaySlib().subscribe(e => {
            var base64PDF = e.fileConntent;
            var fileName = e.fileName;
            const data = `data:application/pdf;base64,${base64PDF}`;
            if (!base64PDF) {
                if (this.localization.currentLanguage.name == "ar")
                    this.message.warn('عفوا لا يوجد سير مرتب لهذا الشهر')
                else
                    this.message.warn('Sorry No payslip for this month')
                return;
            }
            var link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        });
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.mMenuAsideOffcanvas = new MenuAsideOffcanvasDirective(this.mAsideLeft, this.router);
            this.mMenuAsideOffcanvas.ngAfterViewInit();
            if(this.document.body.clientWidth < 768)  {
                this.document.body.classList.toggle('is-menu-collapsed');
            }
        });

        this.layoutRefService.addElement('header', this.mHeader.nativeElement);
    }
    clickTopbarToggle(): void {
        this.document.body.classList.toggle('m-topbar--on');
    }

    clickLeftAsideHideToggle(): void {
        this.document.body.classList.toggle('m-aside-left--hide');
    }

    toggleMenu(): void {
        this.document.body.classList.toggle('is-menu-collapsed');

    }

    downloadUserGuide() {
        window.open('assets/common/pdf/user-guide.pdf', '_blank')
    }
  

}








