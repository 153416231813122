import { Component, Injector, OnInit, ViewEncapsulation, NgZone, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NotificationServiceProxy, UserNotification } from '@shared/service-proxies/service-proxies';
import { IFormattedUserNotification, UserNotificationHelper } from './UserNotificationHelper';
import * as _ from 'lodash';
import { ViewHoursLeaveModalComponent } from '@app/main/requests/hoursLeaves/view-hoursLeave-modal.component';
import { ViewDirectWorkModalComponent } from '@app/main/requests/directWorks/view-directWork-modal.component';
import { ViewGeneralPurposeModalComponent } from '@app/main/requests/generalPurposes/view-generalPurpose-modal.component';
import { ViewLoanModalComponent } from '@app/main/requests/loans/view-loan-modal.component';
import { ViewHrFormModalComponent } from '@app/main/requests/hrForms/view-hrForm-modal.component';
import { ViewOverTimeModalComponent } from '@app/main/requests/overTimes/view-overTime-modal.component';
import { ViewClearanceModalComponent } from '@app/main/requests/clearances/view-clearance-modal.component';
import { ViewVacationModalComponent } from '@app/main/requests/vacations/view-vacation-modal.component';
import { ViewRemoteWorkModalComponent } from '@app/main/requests/remote-works/view-remote-work-modal.component';
import { ViewOrderMaterialModalComponent } from '@app/main/requests/order-materials/view-order-material-modal.component';
import { ViewDeputationModalComponent } from '@app/main/requests/deputations/view-deputation-modal.component';
import { ViewResignationModalComponent } from '@app/main/requests/resignations/view-resignation-modal.component';

@Component({
    templateUrl: './header-notifications.component.html',
    selector: '[headerNotifications]',
    encapsulation: ViewEncapsulation.None
})
export class HeaderNotificationsComponent extends AppComponentBase implements OnInit {

    notifications: IFormattedUserNotification[] = [];
    unreadNotificationCount = 0;
    @ViewChild("viewVacationModal") viewVacationModal: ViewVacationModalComponent;
    @ViewChild("viewClearanceModal") viewClearanceModal: ViewClearanceModalComponent;
    @ViewChild("viewHoursLeaveModal") viewHoursLeaveModal: ViewHoursLeaveModalComponent;
    @ViewChild("viewDirectWorkModal") viewDirectWorkModal: ViewDirectWorkModalComponent;
    @ViewChild("viewGeneralPurposeModal") viewGeneralPurposeModal: ViewGeneralPurposeModalComponent;
    @ViewChild("viewHrFormModal") viewHrFormModal: ViewHrFormModalComponent;
    @ViewChild("viewLoanModal") viewLoanModal: ViewLoanModalComponent;
    @ViewChild("viewOverTimeModal") viewOverTimeModal: ViewOverTimeModalComponent;
    @ViewChild("viewRemoteWorkModal") viewRemoteWorkModal: ViewRemoteWorkModalComponent;
    @ViewChild("viewOrderMaterialModal") viewOrderMaterialModal: ViewOrderMaterialModalComponent;
    @ViewChild("viewDeputationModal") viewDeputationModal: ViewDeputationModalComponent;
    @ViewChild("viewResignationModal") viewResignationModal: ViewResignationModalComponent;


    tempClickedNotificationId: string;

    constructor(
        injector: Injector,
        private _notificationService: NotificationServiceProxy,
        private _userNotificationHelper: UserNotificationHelper,
        public _zone: NgZone
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.loadNotifications();
        this.registerToEvents();

    }

    loadNotifications(): void {
        this._notificationService.getUserNotifications(undefined, 3, 0).subscribe(result => {
            this.unreadNotificationCount = result.unreadCount;
            this.notifications = [];
            _.forEach(result.items, (item: UserNotification) => {
                this.notifications.push(this._userNotificationHelper.format(<any>item));
            });
        });
    }

    registerToEvents() {
        let self = this;

        function onNotificationReceived(userNotification) {
            self._userNotificationHelper.show(userNotification);
            self.loadNotifications();
        }

        abp.event.on('abp.notifications.received', userNotification => {
            self._zone.run(() => {
                onNotificationReceived(userNotification);
            });
        });

        function onNotificationsRefresh() {
            self.loadNotifications();
        }

        abp.event.on('app.notifications.refresh', () => {
            self._zone.run(() => {
                onNotificationsRefresh();
            });
        });

        function onNotificationsRead(userNotificationId) {
            for (let i = 0; i < self.notifications.length; i++) {
                if (self.notifications[i].userNotificationId === userNotificationId) {
                    self.notifications[i].state = 'READ';
                }
            }

            self.unreadNotificationCount -= 1;
        }

        abp.event.on('app.notifications.read', userNotificationId => {
            self._zone.run(() => {
                onNotificationsRead(userNotificationId);
            });
        });
    }

    getNotificationText(record): string {

        if (record.title == "EmployeeDocuments") {
            return this.l(record.text);
        }

        return record.text;
    }

    setAllNotificationsAsRead(): void {
        this._userNotificationHelper.setAllAsRead();
    }

    openNotificationSettingsModal(): void {
        this._userNotificationHelper.openSettingsModal();
    }

    setNotificationAsRead(userNotification: IFormattedUserNotification): void {
        this._userNotificationHelper.setAsRead(userNotification.userNotificationId);
    }

    gotoUrl(url): void {
        if (url) {
            location.href = url;
        }
    }

    onClickNotification(requestType: string, wfRequestId: number, notificationId: string) {
        this.tempClickedNotificationId = notificationId;
        switch (requestType) {
            case "Vacation":
                this.viewVacationModal.showApproval(wfRequestId);
                break;

            case "HourLeave":
                this.viewHoursLeaveModal.showApproval(wfRequestId);
                break;

            case "Clearance":
                this.viewClearanceModal.showApproval(wfRequestId);
                break;

            case "DirectWork":
                this.viewDirectWorkModal.showApproval(wfRequestId);
                break;

            case "GeneralPurpose":
                this.viewGeneralPurposeModal.showApproval(wfRequestId);
                break;

            case "HrForm":
                this.viewHrFormModal.showApproval(wfRequestId);
                break;

            case "Loan":
                this.viewLoanModal.showApproval(wfRequestId);
                break;

            case "OverTime":
                this.viewOverTimeModal.showApproval(wfRequestId);
                break;
            case "EmployeeDocuments":
                {
                    this.setAsReaded();
                    window.open('/app/main/my-profile', '_blank');
                    break;
                }
            case "PaySlip":
                this.setAsReaded();
                break;
            case "RemoteWork":
                this.viewRemoteWorkModal.showApproval(wfRequestId);
                break;
            case "OrderMaterial":
                this.viewOrderMaterialModal.showApproval(wfRequestId);
                break;
            case "Deputation":
                this.viewDeputationModal.showApproval(wfRequestId);
                break;
            case "Resignation":
                this.viewResignationModal.showApproval(wfRequestId);
                break;
            default:
                this.message.error("No Request Type For That Notification , Please Contact System Admin.");
        }
    }

    setAsReaded() {
        this._userNotificationHelper.setAsRead(this.tempClickedNotificationId);
    }
}
