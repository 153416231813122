import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule, TabsModule } from "ngx-bootstrap";
import { TableModule } from "primeng/table";
import { FileUploadModule } from "ng2-file-upload";
import { AutoCompleteModule, EditorModule, FileUploadModule as PrimeNgFileUploadModule, InputMaskModule, PaginatorModule, PanelModule, TreeModule } from "primeng/primeng";
import { UtilsModule } from "@shared/utils/utils.module";
import { OrganizationUnitsTreeComponent } from "./organization-unit-tree.component";
import { GridFiltersComponent } from "../grid-filters/grid-filters.component";
import { FilterItemsComponent } from '../grid-filters/filter-items/filter-items.component';

@NgModule({
    imports: [
        TreeModule,
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TableModule,
        FileUploadModule,
        PrimeNgFileUploadModule,
        PaginatorModule,
        PanelModule,
        UtilsModule
    ],
    declarations: [OrganizationUnitsTreeComponent, GridFiltersComponent, FilterItemsComponent],
    exports: [OrganizationUnitsTreeComponent, GridFiltersComponent, FilterItemsComponent]
})
export class SharedModule { }
