



// new AppMenuItem('MessageReceivers', 'Pages.MessageReceivers', 'flaticon-more', '/app/main/messages/messageReceivers'),

// new AppMenuItem('Messages', 'Pages.Messages', 'flaticon-more', '/app/main/messages/messages'),
import { PermissionCheckerService } from "@abp/auth/permission-checker.service";
import { AppSessionService } from "@shared/common/session/app-session.service";

import { Injectable } from "@angular/core";
import { AppMenu } from "./app-menu";
import { AppMenuItem } from "./app-menu-item";
import { AppComponentBase } from '@shared/common/app-component-base';

@Injectable()
export class AppNavigationService {
    constructor(private _permissionCheckerService: PermissionCheckerService, private _appSessionService: AppSessionService) { }
    dashboardIcon: string = this._appSessionService.theme.baseSettings.theme != 'theme13' ? 'flaticon-line-graph' : 'flaticon-dashboard';
    auditLogsIcon: string = this._appSessionService.theme.baseSettings.theme != 'theme13' ? 'flaticon-folder-1' : 'flaticon-file';
    requestsIcon: string = this._appSessionService.theme.baseSettings.theme != 'theme13' ? 'flaticon-interface-8' : 'flaticon-folder';
    workflowIcon: string = this._appSessionService.theme.baseSettings.theme != 'theme13' ? 'flaticon-interface-8' : 'flaticon-network';
    employeeIcon: string = this._appSessionService.theme.baseSettings.theme != 'theme13' ? 'flaticon-interface-8' : 'flaticon-user';
    masterDataIcon: string = this._appSessionService.theme.baseSettings.theme != 'theme13' ? 'flaticon-interface-8' : 'flaticon-user';
    administrationIcon: string = this._appSessionService.theme.baseSettings.theme != 'theme13' ? 'flaticon-interface-8' : 'flaticon-users';

    getMenu(): AppMenu {
        return new AppMenu("MainMenu", "MainMenu", [
            new AppMenuItem("Dashboard", "Pages.Administration.Host.Dashboard", "flaticon-line-graph", "/app/admin/hostDashboard"),
            new AppMenuItem("Dashboard", "Pages.Tenant.Dashboard", this.dashboardIcon, "/app/main/dashboard"),

            new AppMenuItem("AuditLogs", "Pages.Administration.AuditLogs", this.auditLogsIcon, "/app/admin/auditLogs"),

            new AppMenuItem("AllRequests", "", this.requestsIcon, "", [
                new AppMenuItem("Vacations", "Pages.Vacations", "fas fa-umbrella-beach", "/app/main/requests/vacations"),
                new AppMenuItem("HoursLeaves", "Pages.HoursLeaves", "fas fa-walking", "/app/main/requests/hoursLeaves"),
                new AppMenuItem("Clearances", "Pages.Clearances", "fas fa-walking", "/app/main/requests/clearances"),
                new AppMenuItem('JoiningRejoiningWork', 'Pages.DirectWorks', 'fa fa-briefcase', '/app/main/requests/directWorks'),

                new AppMenuItem('GeneralPurposes', 'Pages.GeneralPurposes', 'flaticon-more', '/app/main/requests/generalPurposes'),
                new AppMenuItem('HrForms', 'Pages.HrForms', 'flaticon-more', '/app/main/requests/hrForms'),
                new AppMenuItem('Loans', 'Pages.Loans', 'flaticon-more', '/app/main/requests/loans'),
                new AppMenuItem('OverTimes', 'Pages.OverTimes', 'flaticon-more', '/app/main/requests/overTimes'),
                new AppMenuItem('Deputations', 'Pages.Deputations', 'flaticon-more', '/app/main/requests/deputations'),
                new AppMenuItem('OrderMaterials', 'Pages.OrderMaterials', 'flaticon-more', '/app/main/requests/orderMaterials'),
                new AppMenuItem('RemoteWorks', 'Pages.RemoteWorks', 'flaticon-more', '/app/main/requests/remoteWorks'),
                new AppMenuItem('Resignations', 'Pages.Resignations', 'flaticon-more', '/app/main/requests/resignations'),

            ]),

            new AppMenuItem("Workflow", "", this.workflowIcon, "", [
                new AppMenuItem("RequestTypes", "Pages.RequestTypes", "flaticon-more", "/app/main/workflow/requestTypes"),

                new AppMenuItem("Workflow", "Pages.RequestTypes", "flaticon-more", "/app/main/workflow/list-of-workflows")
            ]),

            new AppMenuItem("Employee", "", this.employeeIcon, "", [
                new AppMenuItem("Employees", "Pages.Employees", "flaticon-more", "/app/main/employees"),

                new AppMenuItem("EmployeeContacts", "Pages.EmployeeContacts", "flaticon-more", "/app/main/employeeContacts/employeeContacts"),
                new AppMenuItem("EmployeeBanks", "Pages.EmployeeBanks", "flaticon-more", "/app/main/employeeBanks/employeeBanks"),

                new AppMenuItem("EmployeeFamilies", "Pages.EmployeeFamilies", "flaticon-more", "/app/main/employeeFamilies/employeeFamilies"),

                new AppMenuItem("EmployeeDocuments", "Pages.EmployeeDocuments", "flaticon-more", "/app/main/employeeDocuments/employeeDocuments"),
                new AppMenuItem("EmployeeTimesheets", "Pages.Employees.Timesheets", "flaticon-more", "/app/main/employees/timesheet")
            ]),

            new AppMenuItem("Lookups", "", this.masterDataIcon, "", [
                new AppMenuItem("Lookup", "Pages.Administration.Lookups", "flaticon-more", "/app/admin/lookups/lookups"),

                new AppMenuItem("Sectors", "Pages.Sectors", "flaticon-more", "/app/main/sectors/sectors"),

                new AppMenuItem("LeaveCodes", "Pages.LeaveCodes", "flaticon-more", "/app/admin/leavecodes/leavecodes"),

                new AppMenuItem("EmployeeSteps", "Pages.EmployeeSteps", "flaticon-more", "/app/admin/employeesteps/employeesteps"),
                new AppMenuItem("EmployeeAdminStatuses", "Pages.EmployeeAdminStatuses", "flaticon-more", "/app/admin/employeeadminstatuses/employeeadminstatuses"),
                new AppMenuItem("Cities", "Pages.Administration.Cities", "flaticon-more", "/app/admin/cities/cities"),

                new AppMenuItem("Countries", "Pages.Administration.Countries", "flaticon-more", "/app/admin/countries/countries"),

                new AppMenuItem("Branches", "Pages.Administration.Branchs", "flaticon-more", "/app/admin/branches/branchs"),

                new AppMenuItem("Departments", "Pages.Administration.Departments", "flaticon-more", "/app/admin/departments/departments"),

                new AppMenuItem("Jobs", "Pages.Administration.Jobs", "flaticon-more", "/app/admin/jobs/jobs"),
                new AppMenuItem("Positions", "Pages.Administration.Positions", "flaticon-more", "/app/admin/positions/positions"),
                new AppMenuItem("ReasonCodes", "Pages.Administration.ReasonCodes", "flaticon-more", "/app/admin/lookups/reasonCodes")
            ]),

            new AppMenuItem("Administration", "", this.administrationIcon, "", [
                new AppMenuItem('ExportedDatas', 'Pages.ExportedDatas', 'flaticon-more', '/app/main/integrations/exportedDatas'),
                new AppMenuItem('ImportedData', 'Pages.ImportedData', 'flaticon-more', '/app/main/integrations/importedData'),
                new AppMenuItem('ZoneSettings', 'Pages.Administration.ZoneSettings', 'flaticon-more', '/app/admin/zone-settings'),

                new AppMenuItem("OrganizationUnits", "Pages.Administration.OrganizationUnits", "flaticon-map", "/app/admin/organization-units"),

                new AppMenuItem("Roles", "Pages.Administration.Roles", "flaticon-suitcase", "/app/admin/roles"),
                new AppMenuItem("Users", "Pages.Administration.Users", "flaticon-users", "/app/admin/users"),
                // new AppMenuItem(
                //     'LookupTypes',
                //     'Pages.LookupTypes',
                //     'flaticon-more',
                //     '/app/main/lookups/lookupTypes'
                // ),

                new AppMenuItem("Languages", "Pages.Administration.Languages", "flaticon-tabs", "/app/admin/languages"),

                new AppMenuItem("Maintenance", "Pages.Administration.Host.Maintenance", "flaticon-lock", "/app/admin/maintenance"),
                new AppMenuItem("Subscription", "Pages.Administration.Tenant.SubscriptionManagement", "flaticon-refresh", "/app/admin/subscription-management"),
                new AppMenuItem("VisualSettings", "Pages.Administration.UiCustomization", "flaticon-medical", "/app/admin/ui-customization"),
                new AppMenuItem("Settings", "Pages.Administration.Host.Settings", "flaticon-settings", "/app/admin/hostSettings"),
                new AppMenuItem("Settings", "Pages.Administration.Tenant.Settings", "flaticon-settings", "/app/admin/tenantSettings")
            ]),

            new AppMenuItem("Tenants", "Pages.Tenants", "flaticon-list-3", "/app/admin/tenants"),
            new AppMenuItem("Editions", "Pages.Editions", "flaticon-app", "/app/admin/editions")

            // new AppMenuItem(
            //     'DemoUiComponents',
            //     'Pages.DemoUiComponents',
            //     'flaticon-shapes',
            //     '/app/admin/demo-ui-components'
            // ),
            // new AppMenuItem(
            //     'Persons',
            //     null,
            //     'flaticon-book',
            //     '/app/main/persons'
            // )
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === "Pages.Administration.Tenant.SubscriptionManagement" &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
